$google-map-width: 100%;
$google-map-height: 500px;
$google-map-mobile-height: 480px;

.google-map {
    background-color: $platine-color;
    margin: $spacing-big 0;
    height: $google-map-mobile-height;

    @include media-breakpoint-up(md) {
        height: $google-map-height;
    }
}