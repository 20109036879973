$logo-width: 320px;
$logo-mobile-width: 240px;

.page-logo {
    display: block;
    margin-top: $spacing-big;
    margin-bottom: $spacing-medium;
    &__image {
        display: block;
        width: $logo-mobile-width;

        @include media-breakpoint-up(sm) {
            width: $logo-width;
        }

        @include media-breakpoint-up(md) {
            width: $logo-width;
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-bottom: 0;
    }
}