$splash-height: 373px; 

.splash {
    position: relative;
    color: white;
    text-align: center;
    background-color: black;
    background: linear-gradient(
        rgba(0, 0, 0, 0.33), 
        rgba(0, 0, 0, 0.33)
      ),
      url(@images/splash-2-bg.jpg);
    background-size: 100% auto;
    

    @include media-breakpoint-up(sm) {
        text-align: left;
        min-height: $splash-height;
    }

    &__wrapper {
        backdrop-filter: blur(10px);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: $spacing-medium $spacing-default $spacing-big $spacing-default;
        position: static;

        @include media-breakpoint-up(sm) {
            padding: 0;
            position:absolute;
        }

        @include media-breakpoint-up(sm) {
            padding-top: $spacing-big;
        }
    }
    
    &__title {
        color: white;
        font-size: $font-size-small * 2;
        font-family: $font-family-splash-title;
        padding: $spacing-small $spacing-default;

        @include media-breakpoint-up(sm) {
            display: inline-block;
            padding-left: $spacing-big;
            font-size: $font-size-default * 2;
            background-color: $blue-color;
        }
    }

    &__content {
        font-size: $font-size-default;
        font-family: $font-family-splash-content;
        margin-bottom: $spacing-medium;

        br { display: none; }
       
        @include media-breakpoint-up(sm) {
            font-size: $font-size-medium;
            margin: $spacing-small 0 $spacing-big $spacing-big;
            
            br { display: block; }
        }
    }

    &__cta {
        color: white;
        border-color: white;

        @include media-breakpoint-up(sm) {
            margin-left: $spacing-big;
        }
    }
}