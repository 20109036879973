$featured-box-ilu-size: 180px;
$featured-box-ilu-small-size: 110px;

$featured-box-shadow: 0 1px 2px rgba(0,0,0,0.025), 
0 4px 4px rgba(0,0,0,0.025), 
0 8px 8px rgba(0,0,0,0.025), 
0 12px 16px rgba(0,0,0,0.025),
0 20px 32px rgba(0,0,0,0.025), 
0 36px 64px rgba(0,0,0,0.025);

.featured-boxes {
    display: flex;
    align-items: normal;
    list-style: none;
    margin: $spacing-medium 0;
    padding: 0;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
        margin: $spacing-big 0;
    }

}

.featured-box {
    text-align: center;
    margin-bottom: $spacing-default;

    @include media-breakpoint-up(sm) {
        flex-basis: 31%;
        margin: 0;
    }

    @include media-breakpoint-up(lg) {
        text-align: left;
    }

    &__ilu {
        padding: $spacing-default * 2;
        background-color: white;
        width: $featured-box-ilu-size;
        min-height: $featured-box-ilu-size; 
        box-shadow: $featured-box-shadow;
        margin: $spacing-default auto;

        img {
            display: block;
            width: 100%;
        }
        
        @include media-breakpoint-up(sm) {
            width: $featured-box-ilu-small-size;
            min-height: $featured-box-ilu-small-size; 
        }

        @include media-breakpoint-up(md) {
            width: $featured-box-ilu-size;
        }

        @include media-breakpoint-up(lg) {
            padding: $spacing-medium;
            width: $featured-box-ilu-small-size;
            float: left;
            margin: 0;
            margin-right: $spacing-default;
        }
    }
    
    &__title {
        display: block;
        font-size: calc($font-size-large/2);
        font-weight: bold;
        text-transform: uppercase;
        margin: $spacing-small 0 0 0;

        @include media-breakpoint-up(sm) {
            margin: $spacing-small 0;
        }
    }

    &__price {
        color: $light-grey-color;
    }
}