.bus-routes{
    color: $black-color;
    margin: $spacing-big 0;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
    }
}

.bus-route {
    padding-left: $spacing-large;
    background: transparent url(@images/bus-grey-icon.png) 0 0 no-repeat;
    background-size: 70px;
    margin-bottom: $spacing-big;

    @include media-breakpoint-up(md) {
        flex-basis: 50%;
        margin: 0 $spacing-default;
        &:first-child {
            margin-left: 0;
        }
    }

    &__title {
        margin-bottom: $spacing-default;
        font-size: calc($font-size-large/2);
    }
}