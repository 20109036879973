.footer {
    background-color: $black-color;
    color: $platine-color;
    padding: $spacing-medium $spacing-default $spacing-big $spacing-default;

    @include media-breakpoint-up(sm) {
        padding-left: $spacing-medium;
        padding-right: $spacing-medium;
    }

    @include media-breakpoint-up(md) {
        padding-left: $spacing-big;
        padding-right: $spacing-big;
    }

    @include media-breakpoint-up(lg) {
        padding-left: $spacing-large;
        padding-right: $spacing-large;
    }

    &__content {
        font-size: $font-size-small;
        text-align: justify;
    }

    &__inline-image {
        width: 100px;
        margin: 0 $spacing-tiny;
    }

    &__link {

    }
}