.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: $spacing-medium 0;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        padding: $spacing-big $spacing-medium;
    }

    @include media-breakpoint-up(lg) {
        padding: $spacing-big 0;
    }
}