$page-width: 1100px;
$page-mobile-width: 100%;

.skip-link {
    position: absolute;
    left: -1000px;
    top: -1000px;
}

.page-wrapper {
    max-width: $page-mobile-width;
    margin: 0 auto;
    padding: 0 $spacing-small;

    @include media-breakpoint-up(md) {
        max-width: $page-width;
    }
}