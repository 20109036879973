
.wp-block-file {
    margin-bottom: $spacing-big;
    text-align: right;
    .wp-block-file__embed {
        margin-bottom: $spacing-medium;
    }

    .wp-element-button {
        @extend .button;
        @extend .button--primary;
        padding: $spacing-small $spacing-medium;
        margin-left: $spacing-default;
    }
}