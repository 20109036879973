.news {

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    &__item {
        margin: 0 $spacing-default $spacing-big $spacing-default;

        @include media-breakpoint-up(md) {
            &:last-child:nth-child(3n - 2) {
                grid-column-end: 3;
            }
        }
    }

    &__link {
        text-decoration: none;
    }

    &__ilu {
        width: 100%;
        display: block;
    }

    &__section-title {
        font-weight: 300;
        font-size: $font-size-small * 2;
        font-family: $font-family-condensed;
        color: $grey-color;
        margin: $spacing-big 0 $spacing-medium 0;
    }

    &__title {
        padding-left: $spacing-tiny;
        display: block;
        font-size: calc($font-size-large/2);
        color: $link-color;
        font-weight: 400;
        margin: $spacing-small 0;

        &::after {
            content: '»';
        }
    }

    &__date {
        font-size: $font-size-small;
        color: $grey-color;
        padding-left: $spacing-tiny;
        margin-bottom: $spacing-small;
    }

    &__content {
        padding-left: $spacing-tiny;
        color: $grey-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}