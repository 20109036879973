
.page-content > img, 
.illustration {
    display: block;
    width: 100%;
    margin: $spacing-default 0;

    @include media-breakpoint-up(md) {
        width: 400px;
        float: right;
        margin: 0 $spacing-default $spacing-medium $spacing-default;
    }
    
}