
@font-face {
    font-family: 'Source Sans';
    src: local('SourceSans3-Regular.ttf'),
    url('@fonts/SourceSans3-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Source Sans Bold';
    src: local('SourceSans3-SemiBold.ttf'),
    url('@fonts/SourceSans3-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

$font-family-base: 'Roboto', Sans-serif;
$font-family-condensed: 'Roboto Condensed', Sans-serif;
$font-family-splash-title: 'Source Sans Bold', Sans-serif;
$font-family-splash-content: 'Source Sans', Sans-serif;

$font-size-tiny: 12px;
$font-size-small: 14px;
$font-size-default: 16px;
$font-size-medium: 20px;
$font-size-big: 32px;
$font-size-large: 36px;

body {
    font-family: $font-family-base;
    font-size: $font-size-default;
    line-height: 1.4;
}

.page-content {
    padding: 0 $spacing-small;
    
    @include media-breakpoint-up(md) {
        padding: 0 $spacing-default;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 $spacing-small;
    }

    a:not([class*=button]) { color: $link-color; }
    a:not([class*=button]):visited { color: lighten($brand-color, 25%); }
    & > :last-child {
        margin-bottom: $spacing-large;
    }

    .buttons {
        background-color: $platine-color;
        background: radial-gradient(circle, rgba(222,222,222,1) 0%, rgba(255,255,255,1) 100%);
        padding: $spacing-default * 2;
        text-align: center;
        margin: $spacing-default 0;
    }
}

.page-content h1,
.main-title {
    font-weight: 300;
    font-family: $font-family-condensed;
    font-size: $font-size-big;
    margin: $spacing-medium 0;
    color: $grey-color;
    line-height: 1.3;

    @include media-breakpoint-up(md) {
        margin: $spacing-big 0;
        font-size: $font-size-big;
        line-height: 1.3;
    }

    & > strong, 
    &__strong { 
        color: $black-color;
        display: block;

        @include media-breakpoint-up(md) {
          display: inline;
        }
    }

    & em, 
    &__em {
        color: $brand-color;
        font-style: normal;
    }
}

.page-content h2,
.title-2 {
    color: $brand-color;
    text-transform: uppercase;
    font-size: $font-size-medium;
    margin: $spacing-big 0 $spacing-default 0;
}

.page-content h3,
.title-3 {
    color: $black-color;
    font-size: calc($font-size-large/2);
    margin: $spacing-big 0 $spacing-default 0;
}

.page-content p,
.paragraph {
    color: $black-color;
    margin: $spacing-default 0;
    text-align: justify;

    @include media-breakpoint-up(md) {
        text-align: left;
    }
    
    &__em {
        font-style: normal;
        color: $brand-color;
    }

    &--intro {
        padding-left: $spacing-default;
        border-left: $spacing-small lighten($brand-color, 33%) solid;
        color: $grey-color;
        margin-bottom: $spacing-big;
    }
}

.meta {
    margin-top: -$spacing-default;
    margin-bottom: $spacing-big;
    margin-left: $spacing-tiny;
    color: $light-grey-color;

    @include media-breakpoint-up(md) {
        margin-top: -($spacing-small * 5);
    }
    &__posted-on {
        display: inline;
        &::after {
            content: ', ';
        }
    }

    &__posted-on-link {

    }

    &__posted-on-time {

    }

    &__posted-by {
        display: inline;
    }

    &__posted-by-author {
        text-transform: capitalize;
    }

    &__posted-by-author-link {

    }
}