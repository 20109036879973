$yt-preview-shadow: 0 1px 2px rgba(0,0,0,0.07), 
0 2px 4px rgba(0,0,0,0.07), 
0 4px 8px rgba(0,0,0,0.07), 
0 8px 16px rgba(0,0,0,0.07),
0 16px 32px rgba(0,0,0,0.07), 
0 32px 64px rgba(0,0,0,0.07);

.yt-preview {

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    &__item {
        box-shadow: $yt-preview-shadow;
        margin: $spacing-default 0 $spacing-medium 0;  

        @include media-breakpoint-up(lg) {
            flex-basis: 31%;
        }
    }

    &__player {
        iframe {
            width: 100%;
            height: 220px;
            @include media-breakpoint-up(sm) {
                height: 300px;
            }
            @include media-breakpoint-up(lg) {
                height: 220px;
            }
        }
    }

    &__footer {
        display: flex;
        padding: $spacing-small;
        padding-bottom: $spacing-default;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__movie-title {
        display: block;
        font-weight: normal;
        font-size: $font-size-medium;
        color: $black-color;
    }

    &__movie-desc {
        font-size: $font-size-small;
        color: $grey-color;
    }

    &__open-in-new-tab {
        display: block;
        width: $spacing-big;
        img { width: 100%; }
    }
}