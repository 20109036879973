.featured-text-boxes {
    display: flex;
    align-items: end;
    list-style: none;
    margin: $spacing-big 0 $spacing-medium 0;
    padding: 0;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: center;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: $spacing-big;
    }
    
    @include media-breakpoint-up(lg) {
        margin-top: $spacing-large;
    }
}

.featured-text-box {
    margin-bottom: $spacing-medium;

    @include media-breakpoint-up(sm) {
        width: 33%;
        margin: 0;
        padding: 0 $spacing-default;
    }

    @include media-breakpoint-up(md) {
        min-height: $spacing-big * 4.75;
        border-left: 2px $platine-color solid;
        padding: 0 $spacing-default;
        &:first-child { border: 0; }
    }

    @include media-breakpoint-up(lg) {
        text-align: left;
    }

    &__title {
        display: block;
        font-size: calc($font-size-large/2);
        font-family: $font-family-condensed;
        color: $brand-color;
        line-height: 1.4;
        margin-bottom: $spacing-small;

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            min-height: $spacing-large;     
        }
        @include media-breakpoint-up(lg) {
        }
    }

    &__content {
        text-align: justify;
        color: $grey-color;
        font-weight: 400;

        @include media-breakpoint-up(md) {
            text-align: center;
            line-height: 1.4;
        }
    }
}