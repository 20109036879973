$icon-width: 50px;
$icon-mobile-width: 32px;

.socials {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        padding: $spacing-tiny;
    }

    @include media-breakpoint-up(md) {
        width: auto;
    }

    &__item {
        display: flex;
        align-items: center;
        margin: $spacing-small;

        @include media-breakpoint-up(sm) {
            width: 33%;
            padding: $spacing-tiny;
        }

        @include media-breakpoint-up(md) {
            width: auto;
            margin: $spacing-tiny;
        }

        // Excepction for mobile nav-trigger -> see navigations.scss
        &:last-of-type a {
            border: 0;
            padding: $spacing-tiny;
        }
    }

    &__link {
        width: 100%;
        text-align: center;
        font-family: $font-family-condensed;
        font-size: $font-size-default;
        text-decoration: none;
        color: $link-color;
        border: 2px $link-color solid;
        padding: $spacing-small;
        border-radius: $spacing-tiny;

        @include media-breakpoint-up(md) {
            border: 0;
            padding: $spacing-tiny;
        }
    }

    &__label {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__image {
        width: $icon-mobile-width;
        display: none;
        @include media-breakpoint-up(md) {
            width: $icon-width;
            display: block;
        }
    }
}