.newsletter {
    text-align: center;
    background: linear-gradient(180deg, rgba(153,149,239,1) 0%, rgba(240,194,216,1) 100%);
    padding: $spacing-medium $spacing-default;

    @include media-breakpoint-up(sm) {
        padding: $spacing-medium * 3 $spacing-large;
    }

    @include media-breakpoint-up(lg) {

    }

    &__title {
        font-weight: 300;
        font-family: $font-family-condensed;
        font-size: $font-size-small * 2;
        color: white;

        @include media-breakpoint-up(sm) {
            font-size: $font-size-big;
        }
    }

    &__intro {
        color: white;
        text-align: justify;
        margin: $spacing-default 0;

        @include media-breakpoint-up(md) {
            width: 75%;
            margin: $spacing-default auto $spacing-big auto;
            text-align: center;
        }
    }

    &__form {
        margin: $spacing-default 0;
    }

    &__fieldset-label,
    &__formgroup-label {
        display: none;
    }

    &__checkbox-label {
        color: white;
    }

    &__formgroup {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: $spacing-default;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            margin: 0 0 $spacing-default 0;
        }

        &--left {
            
        }
    }

    &__input {
        width: 100%;
        border: 1px white solid;
        padding: $spacing-default;
        font-size: $font-size-medium;

        @include media-breakpoint-up(sm) {
            width: 66%;
        }
    }

    &__submit {
        border-radius: 0;
        width: 100%;
        
        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }
}