$trigger-size: 40px;

.nav-trigger {
    border: 0;
    background-color: transparent;
    width: $trigger-size;
    display: block;
    position: fixed;
    top: $spacing-small;
    right: $spacing-medium;
    z-index: 1;
    padding: 0;

    @include media-breakpoint-up(md) {
        margin-left: $spacing-big;
        margin-right: $spacing-medium;
        position: static;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }

    &__icon {
        display: block;
        width: 100%;
    }
}

.pri-nav {
    z-index: 1000;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transform: translateY(-100%);
    opacity: 0;
    will-change: transform;
    transition: transform 0.4s ease, opacity 0.5s ease;
    overflow: hidden;
    
    @include media-breakpoint-up(md) {
        margin-bottom: $spacing-tiny;
    }
    
    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
        position: static;
        width: auto;
        height: auto;
        opacity: 1;
        transform: translateY(0);
        margin-bottom: $spacing-big;
    }

    &__item {
        font-size: calc($font-size-big/2);
        text-transform: uppercase;
        text-decoration: none;
        padding: $spacing-default;
        border-bottom: 1px $platine-color solid;
        display: block;

        @include media-breakpoint-up(sm) {}

        @include media-breakpoint-up(md) {}

        @include media-breakpoint-up(lg) {
            border: 0;
        }

        &--active {
            position: relative;
            color: $brand-color;

            &::after {
                color: $grey-color;
                font-size: $font-size-tiny;
                text-transform: none;
                display: block;
                content: '← Tu jesteś';
                position: absolute;
                top: 50%;
                right: $spacing-default;
                margin-top: -(calc($font-size-default/2));
            }

            @include media-breakpoint-up(lg) {
                color: white;
                background-color: $brand-color;

                &::after {
                    display: none;
                }
            }
        }

        &--close {
            width: 50%;
            position: fixed;
            bottom: $spacing-big;
            left: 50%;
            display: block;
            text-align: center;
            text-decoration: none;
            padding: $spacing-default;
            margin-left: -25%;
            border: 1px $grey-color solid;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}

.pri-nav:target {
    opacity: 1;
    transform: translateY(0);
}