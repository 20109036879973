.contact-boxes {
    margin: $spacing-big 0;

    @include media-breakpoint-up(md) {
        display: flex;   
        align-items: center;
        justify-content: left;
    }
}

.contact-box {
    color: $black-color;
    margin: $spacing-small $spacing-medium 0 0;
    padding: $spacing-small;
    border-bottom: 1px $platine-color solid;

    &:only-child, &:last-child {
        border: 0;
    }

    @include media-breakpoint-up(md) {
        border: 0;
    }

    &__email {
        display: block;
        font-size: $font-size-small;
        border: 1px $link-color solid;
        border-radius: $spacing-tiny;
        padding: $spacing-small;
        text-decoration: none;

        @include media-breakpoint-up(md) {
            border: 0;
            border-radius: 0;
            padding: 0;
            text-decoration: underline;
        }
    }

    &__phone {
        font-size: $font-size-small;
        margin-top: $spacing-small;
        margin-bottom: $spacing-tiny;
        border: 1px $light-grey-color solid;
        border-radius: $spacing-tiny;
        padding: $spacing-small;

        @include media-breakpoint-up(md) {
            border: 0;
            padding: 0;
            border-radius: 0;
        }

        img {
            display: inline-block;
            vertical-align: middle;
            margin-right: $spacing-tiny;
            width: $spacing-medium;
        }


    }
}

.page-content > .contact-box {
    padding: 0;
    border: 0;
    .contact-box__phone {
        font-size: $font-size-default;
    }
}