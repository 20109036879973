.inline-links {
    margin: $spacing-medium 0;
    
    &__link {
        color: $link-color;
        display: inline;

        &::after {
            content: '|';
            color: $light-grey-color;
            margin: 0 $spacing-small;
        }

        &:last-child:after {
            content: none;
            margin: 0;
        }
    }
}