$counter-box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
0 2px 4px rgba(0,0,0,0.07), 
0 4px 8px rgba(0,0,0,0.07), 
0 8px 16px rgba(0,0,0,0.07),
0 16px 32px rgba(0,0,0,0.07), 
0 32px 64px rgba(0,0,0,0.07);

$counter-height: 360px;

.counter,
.call-for-apps-finished {
    min-height: $counter-height;
    color: $brand-color;
    box-shadow: $counter-box-shadow, 0 0 $spacing-big rgba(255,255,255, 0.5) inset;
    padding: $spacing-medium $spacing-small;
    text-align: center;
    background: linear-gradient(0deg, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 34%, rgba(224,224,224,1) 100%);
    margin-top: $spacing-medium;
    
    &.hidden { 
        display: none; 
    }

    @include media-breakpoint-up(md) {
        padding: $spacing-medium;
        margin-top: 0;
    }

    &__title {
        font-weight: bold;
    }

    &__digits {
        display: flex;
        margin: $spacing-medium;
    }
}

.digit {
    width: 25%; 
    margin: 0 $spacing-tiny;

    &__value {
        color: white;
        background-color: $brand-color;
        padding: $spacing-small 0;
        font-family: $font-family-condensed;
        font-size: $font-size-tiny * 2;
        min-height: $spacing-default * 3;
    }

    &__label {
        margin-top: $spacing-small;
        text-transform: uppercase;
    }
}

.call-for-apps-finished {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}